import _toConsumableArray from "/builds/u5yGSXwH/2/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import _toArray from "/builds/u5yGSXwH/2/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toArray.js";
import _defineProperty from "/builds/u5yGSXwH/2/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/builds/u5yGSXwH/2/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useCategoryProductSearchQuery } from '@src/queries/CategoryProductSearchQuery.generated';
import useCategoryProductSearchQueryParams from './useCategoryProductSearchQueryParams';
import useCacheCategoryProductSearch, { DEFAULT_SEARCH_RESULTS } from './useCacheCategoryProductSearch';
var DELIMITERS = {
  AUTHORED_FACET_OPTIONS: ':',
  PREFIX: '/'
};

var getAllSelectedFacets = function getAllSelectedFacets(authorSelectedFacets, userSelectedFacets) {
  var authorSelectedFacetMap = authorSelectedFacets.reduce(function (map, facet) {
    var _facet$split = facet.split(DELIMITERS.AUTHORED_FACET_OPTIONS),
        _facet$split2 = _slicedToArray(_facet$split, 2),
        key = _facet$split2[0],
        value = _facet$split2[1];

    if (!map[key]) {
      map[key] = [value];
    } else {
      map[key].push(value);
    }

    return map;
  }, {});

  var fullMap = _objectSpread(_objectSpread({}, authorSelectedFacetMap), userSelectedFacets);

  return Object.keys(fullMap).map(function (key) {
    return {
      key: key,
      options: fullMap[key]
    };
  });
};

var getUnprefixedValue = function getUnprefixedValue(value) {
  var _value$split = value.split(DELIMITERS.PREFIX),
      _value$split2 = _toArray(_value$split),
      firstPart = _value$split2[0],
      restParts = _value$split2.slice(1);

  return restParts.length === 0 ? firstPart : restParts.join(DELIMITERS.PREFIX);
};

var useCategoryProductSearch = function useCategoryProductSearch(config) {
  var _data$getProductSearc;

  var _config$authorSelecte = config.authorSelectedFacets,
      authorSelectedFacets = _config$authorSelecte === void 0 ? [] : _config$authorSelecte,
      _config$authorCurated = config.authorCuratedFacets,
      authorCuratedFacets = _config$authorCurated === void 0 ? [] : _config$authorCurated,
      defaultSort = config.defaultSort,
      defaultPerPage = config.defaultPerPage;

  var _useCategoryProductSe = useCategoryProductSearchQueryParams(defaultSort, defaultPerPage),
      pageState = _useCategoryProductSe.page,
      perPageState = _useCategoryProductSe.perPage,
      searchTerm = _useCategoryProductSe.searchTerm,
      searchedFor = _useCategoryProductSe.searchedFor,
      sort = _useCategoryProductSe.sort,
      selectedFacets = _useCategoryProductSe.selectedFacets,
      setQueryParams = _useCategoryProductSe.setQueryParams;

  var _useCategoryProductSe2 = useCategoryProductSearchQuery({
    variables: {
      searchTerm: searchTerm,
      page: pageState,
      perPage: perPageState,
      sort: sort,
      selectedFacets: getAllSelectedFacets(authorSelectedFacets, selectedFacets),
      facetSet: authorCuratedFacets
    }
  }),
      data = _useCategoryProductSe2.data,
      loading = _useCategoryProductSe2.loading,
      error = _useCategoryProductSe2.error;

  var nextSearchResults = (_data$getProductSearc = data === null || data === void 0 ? void 0 : data.getProductSearchResults) !== null && _data$getProductSearc !== void 0 ? _data$getProductSearc : DEFAULT_SEARCH_RESULTS;
  var cachedSearchResults = useCacheCategoryProductSearch(nextSearchResults, loading);
  var term = searchTerm;
  var searched = searchedFor;
  var searchResults = loading ? cachedSearchResults : nextSearchResults;

  var products = _toConsumableArray(searchResults.items);

  var facets = searchResults.facets.map(function (facet) {
    var _selectedFacets$facet;

    var selectedOptions = (_selectedFacets$facet = selectedFacets[facet.key]) !== null && _selectedFacets$facet !== void 0 ? _selectedFacets$facet : [];
    var formattedSelectedOptions = facet.prefix ? selectedOptions.map(getUnprefixedValue) : selectedOptions;
    var options = facet.options.map(function (option) {
      return _objectSpread(_objectSpread({}, option), {}, {
        selected: formattedSelectedOptions.includes(option.value)
      });
    });
    return _objectSpread(_objectSpread({}, facet), {}, {
      options: options
    });
  });
  var _searchResults$metada = searchResults.metadata,
      numPages = _searchResults$metada.numPages,
      itemCount = _searchResults$metada.itemCount,
      page = _searchResults$metada.page,
      perPage = _searchResults$metada.perPage;
  var maxCountForPage = page * perPage;
  var pageEnd = (itemCount || 0) < maxCountForPage ? itemCount : maxCountForPage;
  var pageStart = maxCountForPage - perPage + 1;

  var updateSearchTerm = function updateSearchTerm(term, searched) {
    return setQueryParams({
      term: term,
      page: 1,
      searched: searched
    });
  };

  var updatePage = function updatePage(page) {
    return setQueryParams({
      page: page
    });
  };

  var updateResultsPerPage = function updateResultsPerPage(perPage) {
    return setQueryParams({
      perPage: perPage,
      page: 1
    });
  };

  var updateSort = function updateSort(sort) {
    return setQueryParams({
      sort: sort,
      page: 1
    });
  };

  var updateFacets = function updateFacets(facet, clearAll) {
    if (clearAll) {
      setQueryParams({
        facets: {},
        page: 1,
        term: ''
      });
      return;
    }

    var key = facet.key,
        value = facet.value,
        prefix = facet.prefix;

    var nextSelectedFacets = _objectSpread({}, selectedFacets);

    var currentValues = nextSelectedFacets[key];
    var storedValue = prefix ? "".concat(prefix).concat(value) : value;
    var shouldRemove = currentValues && currentValues.includes(storedValue);
    var nextValues = [];

    if (shouldRemove) {
      var removableItemIndex = currentValues.indexOf(value);
      nextValues = _toConsumableArray(currentValues);
      nextValues.splice(removableItemIndex, 1);
    } else {
      nextValues = [value];

      if (currentValues) {
        nextValues = currentValues.concat(nextValues);
      }
    }

    nextSelectedFacets[key] = prefix ? nextValues.map(function (value) {
      return "".concat(prefix).concat(value);
    }) : nextValues;

    if (nextValues.length === 0) {
      delete nextSelectedFacets[key];
    }

    setQueryParams({
      facets: nextSelectedFacets,
      page: 1
    });
  }; // this will wipe out all other facets and apply this set only.


  var applyFacets = function applyFacets(facets) {
    var nextSelectedFacets = {};
    facets.forEach(function (f) {
      var prefix = f.prefix || '';
      nextSelectedFacets[f.key] = ["".concat(prefix).concat(f.value)];
    });
    setQueryParams({
      facets: nextSelectedFacets,
      page: 1,
      term: ''
    });
  };

  var selectedFacetsForView = Object.keys(selectedFacets).reduce(function (nextSelectedFacets, key) {
    var options = selectedFacets[key];
    options.forEach(function (value) {
      nextSelectedFacets.push({
        key: key,
        value: getUnprefixedValue(value)
      });
    });
    return nextSelectedFacets;
  }, []);
  return {
    facets: facets,
    products: products,
    error: error,
    loading: loading,
    pageEnd: pageEnd,
    pageStart: pageStart,
    numPages: numPages,
    page: page,
    sort: sort,
    term: term,
    itemCount: itemCount,
    searchTerm: searchTerm,
    searched: searched,
    selectedFacets: selectedFacetsForView,
    updateSearchTerm: updateSearchTerm,
    updatePage: updatePage,
    updateResultsPerPage: updateResultsPerPage,
    updateSort: updateSort,
    updateFacets: updateFacets,
    applyFacets: applyFacets
  };
};

export default useCategoryProductSearch;